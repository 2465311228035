import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import {IconButton} from '@mui/material';

// i18n
import {useTranslation} from 'react-i18next';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TransitionsModal = ({open, onClose, children}) => {
  const {t, i18n} = useTranslation();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box sx={{position: 'relative'}}>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
              }}
              onClick={() => onClose()}
            >
              <CloseIcon />
            </IconButton>
            <Box>{children}</Box>
            <Box sx={{textAlign: 'end'}}>
              <Button onClick={() => onClose()} variant="contained">
                {t('Close')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
export default TransitionsModal;
