/**
 * References:
 * - https://react.i18next.com/guides/quick-start#configure-i-18-next
 * - https://react.i18next.com/latest/using-with-hooks#configure-i-18-next
 * - https://react.i18next.com/latest/i18next-instance
 */
import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {getLang} from './utils';
import translationEN_US from '../assets/locales/en_us/translation.json';
import translationZH_TW from '../assets/locales/zh_tw/translation.json';
import translationZH_CN from '../assets/locales/zh_cn/translation.json';

export const DEFAULT_LANG = 'en_us';

const resources = {
  en_us: {translation: translationEN_US},
  zh_tw: {translation: translationZH_TW},
  zh_cn: {translation: translationZH_CN},
};

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    resources,
    fallbackLng: 'en_us',
    lngs: ['en_us', 'zh_tw', 'zh_cn'],
    debug: false,
    react: {
      useSuspense: false,
    },
    // detection: {
    //   order: ['cookie'],
    //   caches: ['cookie'],
    //   cookieMinutes: 24 * 60 * 7,
    // },
    defaultNS: 'translation',
    nsSeparator: false, // namespace separator
    keySeparator: false, // key separator
    interpolation: {
      prefix: '{{',
      suffix: '}}',
    },
  });

export {i18next};
