// import {useEffect, useState} from 'react';
// UI
import theme from './theme';
import 'react-toastify/dist/ReactToastify.css';
// Redux
import {useSelector} from 'react-redux';
import {selectDiscordUserInfo, IRoles} from './store/loginUserInfoSlice';
import {ThemeProvider, StyledEngineProvider} from '@material-ui/core';
// Auth0
import {
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
} from '@auth0/auth0-react';
// Routes
import {useRoutes} from 'react-router-dom';
// Types
import {getRoutes} from './routes';

function App() {
  // const discordUserInfo = useSelector(selectDiscordUserInfo);
  // const {
  //   // user,
  //   isAuthenticated,
  //   isLoading,
  //   // loginWithRedirect,
  //   // logout,
  //   // getAccessTokenSilently,
  //   // getIdTokenClaims,
  // } = useAuth0();

  // const roles = user?.user_metadata?.roles?.split(",").map((val) => {val?.trim()})
  // const isManager = roles?.includes(IRoles.MANAGER);

  const content = useRoutes(getRoutes(true, true));

  return (
    <Auth0Provider
      // domain="nfsc.us.auth0.com"
      // clientId="fb71wczl4fnKJEAXetP0PtDsWQUpnnRj"
      // audience="https://nfscdb-web.gnewsapp.com/api/"
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      audience={process.env.REACT_APP_AUTH0_API_AUDIENCE}
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
      // redirectUri={'http://localhost:3000/login'}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>{content}</ThemeProvider>
      </StyledEngineProvider>
    </Auth0Provider>
  );
}

export default App;
