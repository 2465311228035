import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import {toast} from 'react-toastify';
import lodash from 'lodash';

import TransitionsModal from '../TransitionsModal';
// Redux
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuid} from 'uuid';
// import {
//   LoginUserType,
//   selectLoginUserType,
//   setAdminLogout,
//   selectDiscordUserInfo,
//   setDiscordUserLogout,
// } from '../../store/loginUserInfoSlice';

import {styled} from '@mui/material/styles';
// Mui
import {Paper, Box, Grid, Typography, Tooltip, Theme} from '@mui/material';
import {makeStyles} from '@material-ui/styles';
import {DataGrid, GridColDef, GridValueGetterParams} from '@mui/x-data-grid';

import {Icon} from 'react-feather';

// API
import // queryFarmMemberAccountInfo,
// queryAllFarmMembersInvestInfo,
// queryAllFarmMembersCoinInfo,
// testQueryPersonalInvestment,
// queryPersonalCoinInfo,
// queryPersonalInvestInfoList,
// queryPersonalInvestInfoDetail,
// DataSource,
'../../api';
import {CoinInfo, PageInfo} from '../../api/types';

// Router
import {useNavigate, useLocation} from 'react-router-dom';

// i18n
import {useTranslation} from 'react-i18next';

// Auth0
import {useAuth0} from '@auth0/auth0-react';

const useStyle = makeStyles((theme: Theme) => {
  return {
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
    },
    paper: {
      flex: 1,
    },
  };
});

interface DataGridDataState {
  cachedPagedRows: {
    [key: number]: CoinInfo[]; // key is pageIndex
  };
  apiPageInfo: Partial<PageInfo>;
}

interface CoinInfoDataGridProps {
  filterDiscordId?: string;
  fetcherApi: (...args: any[]) => Promise<any>;
}

export const CoinInfoDataGrid: React.FC<CoinInfoDataGridProps> = ({
  filterDiscordId,
  fetcherApi,
}) => {
  const [row, setRow] = useState(null);
  const [detailOpen, setDetailOpen] = useState(false);
  const {user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims} =
    useAuth0();

  const {t, i18n} = useTranslation();

  /** Data grid states */
  const initialDataGridStates = {
    cachedPagedRows: {}, // loaded page row data
    apiPageInfo: {
      pageSize: 50,
      totalRows: 0,
    },
  };
  const [dataGridData, setDataGridData] = useState<DataGridDataState>({
    ...initialDataGridStates,
  });
  const resetDataGridStates = () => setDataGridData({...initialDataGridStates});

  const [dataGridCurrentPageNum, setDataGridCurrentPageNum] =
    useState<number>(0); // The zero-indexed data grid page num is shared by both coinInfo list & investInfo list
  const dataGridPageSize = 10;

  /** Collect all rows in cachedPagedRows into a single array */
  const congregatedCachedRows = useMemo(() => {
    const rows = [] as CoinInfo[];
    for (let key of Object.keys(dataGridData.cachedPagedRows)) {
      rows.push(...dataGridData.cachedPagedRows[key]);
    }
    // return filterDiscordId
    //   ? rows.filter((item) => item.discord === filterDiscordId)
    //   : rows;
    return rows;
  }, [dataGridData]);

  const {pageSize: apiPageSize, totalRows: apiTotalRows} =
    dataGridData.apiPageInfo;

  /** Calculate which api page index we're on based on data grid's page number
   *  (Since api page size is different from data grid's page size)
   */
  const currentPageIndex = useMemo(
    () =>
      // DataGrid pageNum is zero-indexed
      Math.ceil(
        ((dataGridCurrentPageNum + 1) * dataGridPageSize) / apiPageSize,
      ),
    [dataGridCurrentPageNum, dataGridPageSize, apiPageSize],
  );

  /** Generate column headers */
  const memoizedColumns = useMemo(() => {
    const result: GridColDef[] = CoinInfoColumnNames.map((field) => ({
      field: field.name,
      headerName: lodash.startCase(t(field.name)),
      width: field.width || 140,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Tooltip title={params.formattedValue}>
          <div className="MuiDataGrid-cellContent">
            {' '}
            {params.formattedValue}{' '}
          </div>
        </Tooltip>
      ),
      valueFormatter: (params) => valueFormatterFn(params.value, field.format),
    }));
    return result;
  }, [CoinInfoColumnNames, t, i18n]);

  // Loading states
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchFarmCoinInfoList = async (pageIndex: number, discord?: string) => {
    setIsLoading(true);
    const accessToken = await getAccessTokenSilently({
      // audience: 'https://nfscdb-app.gnewsapp.com/api/',
      audience: `${process.env.REACT_APP_AUTH0_API_AUDIENCE}`,
    });
    fetcherApi(pageIndex, accessToken, discord).then((res) => {
      const data = res.data.data;
      if (!data) {
        toast.warning(`${t('System Error')}, ${res.data.message}`);
        return;
      }
      const {list, pageInfo} = data;
      setDataGridData((prevState) => ({
        cachedPagedRows: Object.assign(
          {...prevState.cachedPagedRows},
          {[pageIndex]: list},
        ),
        apiPageInfo:
          prevState.apiPageInfo.totalRows === 0
            ? {pageSize: pageInfo.pageSize, totalRows: pageInfo.totalRows}
            : {...prevState.apiPageInfo},
      }));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!dataGridData.cachedPagedRows[currentPageIndex]) {
      fetchFarmCoinInfoList(currentPageIndex, filterDiscordId);
    }
  }, [currentPageIndex]);

  useEffect(() => {
    resetDataGridStates();
    setDataGridCurrentPageNum(0);
    fetchFarmCoinInfoList(1, filterDiscordId);
  }, [filterDiscordId]);

  return (
    <>
      <DataGrid
        loading={isLoading}
        onPageChange={(pageNum) => setDataGridCurrentPageNum(pageNum)}
        columns={memoizedColumns}
        page={dataGridCurrentPageNum}
        rows={congregatedCachedRows}
        pageSize={dataGridPageSize}
        rowCount={filterDiscordId ? undefined : apiTotalRows}
        onRowClick={({row}) => {
          setRow(row);
          setDetailOpen(true);
        }}
        disableSelectionOnClick
        disableColumnMenu
        autoHeight
        components={{
          BaseTooltip: Tooltip,
        }}
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-row': {
            ':hover': {
              cursor: 'pointer',
            },
          },
        }}
        disableDensitySelector
        // experimentalFeatures={{ newEditingApi: true }}
      />
      <TransitionsModal open={detailOpen} onClose={() => setDetailOpen(false)}>
        {DetailPage(row)}
      </TransitionsModal>
    </>
  );
};

const DetailPage = (data) => {
  const {t} = useTranslation();

  const column = CoinInfoColumnNames.map((item) => ({
    name: item.name,
    key: item.name,
  }));

  if (!data) {
    return <div></div>;
  }

  return (
    <Box
      sx={{
        width: '80vw',
      }}
    >
      <Typography
        component={'h1'}
        sx={{
          fontSize: 24,
          fontWeight: 500,
        }}
      >
        {t('Detail')}
      </Typography>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{xs: 2, sm: 2, md: 6}}
        sx={{mt: 2}}
      >
        {column.map(({name, key}) => {
          return (
            <Grid key={key} item xs={6} md={6} sx={{alignSelf: 'center'}}>
              <Box
                component={'div'}
                sx={{display: 'flex', justifyContent: 'space-between'}}
              >
                <Box component={'span'} sx={{fontSize: 18, fontWeight: 500}}>
                  {name}
                </Box>
                <Box component={'span'} sx={{fontSize: 18, fontWeight: 500}}>
                  {data[key]}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

enum ValueType {
  Number = 'NUMBER',
  Date = 'Date',
}

interface ColumnField {
  name: string;
  format?: ValueType;
  width?: number; // data grid cell width
}

const CoinInfoColumnNames: ColumnField[] = [
  {name: 'id', width: 60},
  {name: 'discord', width: 160},
  {name: 'memberId'},
  {name: 'farmId'},
  {name: 'email'},
  {name: 'hcnInfo', format: ValueType.Number},
  {name: 'investHcn', format: ValueType.Number},
  {name: 'benefitHcn', format: ValueType.Number},
  {name: 'calculationHcn', format: ValueType.Number},
  {name: 'calculationHoldByOthers', format: ValueType.Number},
  {name: 'calculationHoldForOthers', format: ValueType.Number},
  {name: 'icoHcn', format: ValueType.Number},
  {name: 'toFmv', format: ValueType.Number},
  {name: 'holdForOthers', format: ValueType.Number},
  {name: 'holdByOthers', format: ValueType.Number},
  {name: 'createTime', format: ValueType.Date},
  {name: 'updateTime', format: ValueType.Date},
  {name: 'remark'},
];

const valueFormatterFn = (value?: string, format?: ValueType) => {
  if (value != null && value !== '') {
    switch (format) {
      case ValueType.Date:
        const conversionMultiplyer = `${value}`.length < 13 ? 1000 : 1;
        return new Date(parseInt(value) * conversionMultiplyer)
          .toISOString()
          .split('T')[0];
      case ValueType.Number:
        return parseInt(value).toLocaleString('en-US');
      case undefined:
        return value;
      default:
        return value;
    }
  }
  return '';
};
