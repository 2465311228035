import {useState} from 'react';

// i18n
import {useTranslation} from 'react-i18next';

// Mui
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
} from '@material-ui/core';

// Icons
import {
  Menu as MenuIcon,
  NotificationsOutlined as NotificationsIcon,
  // Language as LanguageIcon,
  Input as InputIcon,
  Translate as LanguageIcon,
} from '@material-ui/icons';
import Logo from './Logo';

// Redux
import {useDispatch} from 'react-redux';
import {setLogout} from '../store/loginUserInfoSlice';

// Router
import {Link as RouterLink} from 'react-router-dom';

// Cookie
import {useCookies} from 'react-cookie';

// Toast
import {toast} from 'react-toastify';

// Auth0
import {useAuth0} from '@auth0/auth0-react';

const DashboardNavbar = ({onMobileNavOpen, ...rest}) => {
  const [notifications] = useState([]);
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const [_, __, removeCookie] = useCookies(['token']);
  const {logout} = useAuth0();

  // Menu states
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);
    handleClose();
  };

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{flexGrow: 1}} />
        <IconButton
          color="inherit"
          size="large"
          // onClick={() =>
          //   i18n.changeLanguage(i18n.language === 'en_us' ? 'zh_cn' : 'en_us')
          // }
          onClick={handleClick}
        >
          <Badge
            badgeContent={notifications.length}
            color="primary"
            variant="dot"
          >
            <LanguageIcon />
          </Badge>
        </IconButton>
        <IconButton
          onClick={() => {
            // dispatch(setLogout());
            logout({returnTo: window.location.origin});
            removeCookie('token');
            // toast.success(t('Logout Success'), {
            //   toastId: 'SUCCESSFUL_LOGOUT',
            // });
          }}
          color="inherit"
          size="large"
          data-id="logout...."
        >
          <InputIcon />
        </IconButton>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Menu
        id="language-select-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // MenuListProps={{
        //   'aria-labelledby': 'basic-button',
        // }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => changeLanguage('en_us')}>English</MenuItem>
        <MenuItem onClick={() => changeLanguage('zh_cn')}>简体中文</MenuItem>
      </Menu>
    </AppBar>
  );
};

export default DashboardNavbar;
