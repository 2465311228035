import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import {toast} from 'react-toastify';
import {InvestInfo, PageInfo} from '../../api/types';
import lodash from 'lodash';

import TransitionsModal from '../TransitionsModal';
// Redux
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuid} from 'uuid';
// import {
//   LoginUserType,
//   selectLoginUserType,
//   setAdminLogout,
//   selectDiscordUserInfo,
//   setDiscordUserLogout,
// } from '../../store/loginUserInfoSlice';

import {styled} from '@mui/material/styles';
// Mui
import {Paper, Box, Grid, Typography, Tooltip, Theme} from '@mui/material';
import {makeStyles} from '@material-ui/styles';
import {DataGrid, GridColDef, GridValueGetterParams} from '@mui/x-data-grid';

import {Icon} from 'react-feather';

// API
import {
  // queryFarmMemberAccountInfo,
  // queryAllFarmMembersInvestInfo,
  // queryAllFarmMembersCoinInfo,
  // testQueryPersonalInvestment,
  // queryPersonalCoinInfo,
  // queryPersonalInvestInfoList,
  // queryPersonalInvestInfoDetail,
  DataSource,
} from '../../api';
import {useQueryFarmMemberAccountInfoQuery} from '../../api/all';

// Router
import {useNavigate, useLocation} from 'react-router-dom';

// i18n
import {useTranslation} from 'react-i18next';

// Auth0
import {useAuth0} from '@auth0/auth0-react';

const useStyle = makeStyles((theme: Theme) => {
  return {
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
    },
    paper: {
      flex: 1,
    },
  };
});

interface DataGridDataState {
  cachedPagedRows: {
    [key: number]: InvestInfo[]; // key is pageIndex
  };
  apiPageInfo: Partial<PageInfo>;
}

interface InvestInfoDataGridProps {
  dataSource: DataSource;
  filterDiscordId?: string;
  fetcherApi: (...args: any[]) => Promise<any>;
}

export const InvestInfoDataGrid: React.FC<InvestInfoDataGridProps> = ({
  dataSource,
  filterDiscordId,
  fetcherApi,
}) => {
  const [row, setRow] = useState(null);
  const [detailOpen, setDetailOpen] = useState(false);
  const {user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims} =
    useAuth0();

  // const claims = await getIdTokenClaims();
  // const discord = claims['x-nfscdb/discord'];

  const {t, i18n} = useTranslation();

  /** Data grid states */
  const initialDataGridStates = {
    cachedPagedRows: {}, // loaded page row data
    apiPageInfo: {
      pageSize: 50,
      totalRows: 0,
    },
  };
  const [dataGridData, setDataGridData] = useState<DataGridDataState>({
    ...initialDataGridStates,
  });
  const resetDataGridStates = () => setDataGridData({...initialDataGridStates});

  const [dataGridCurrentPageNum, setDataGridCurrentPageNum] =
    useState<number>(0); // The zero-indexed data grid page num is shared by both coinInfo list & investInfo list
  const dataGridPageSize = 10;

  /** Collect all rows in cachedPagedRows into a single array */
  const congregatedCachedRows = useMemo(() => {
    const rows = [] as InvestInfo[];
    for (let key of Object.keys(dataGridData.cachedPagedRows)) {
      rows.push(...dataGridData.cachedPagedRows[key]);
    }
    // return filterDiscordId
    //   ? rows.filter((item) => item.discord === filterDiscordId)
    //   : rows;
    return rows;
  }, [dataGridData]);

  const {pageSize: apiPageSize, totalRows: apiTotalRows} =
    dataGridData.apiPageInfo;

  /** Calculate which api page index we're on based on data grid's page number
   *  (Since api page size is different from data grid's page size)
   */
  const currentPageIndex = useMemo(
    () =>
      // DataGrid pageNum is zero-indexed
      Math.ceil(
        ((dataGridCurrentPageNum + 1) * dataGridPageSize) / apiPageSize,
      ),
    [dataGridCurrentPageNum, dataGridPageSize, apiPageSize],
  );

  /** Generate column headers */
  const memoizedColumns = useMemo(() => {
    const result: GridColDef[] = InvestInfoColumnNames.map((field) => ({
      field: field.name,
      headerName: lodash.startCase(t(field.name)),
      width: field.width || 140,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Tooltip title={params.formattedValue}>
          <div className="MuiDataGrid-cellContent">
            {' '}
            {params.formattedValue}{' '}
          </div>
        </Tooltip>
      ),
      valueFormatter: (params) => valueFormatterFn(params.value, field.format),
    }));
    return result;
  }, [InvestInfoColumnNames, t, i18n]);

  // Loading states
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchFarmInvestInfoList = async (
    source: DataSource,
    pageIndex: number,
    discord?: string,
  ) => {
    setIsLoading(true);
    const accessToken = await getAccessTokenSilently({
      // audience: 'https://nfscdb-app.gnewsapp.com/api/',
      audience: `${process.env.REACT_APP_AUTH0_API_AUDIENCE}`,
    });
    // return fetcherApi(pageIndex, source, accessToken, discord);
    fetcherApi(pageIndex, source, accessToken, discord).then((res) => {
      const data = res.data.data;
      if (!data) {
        toast.warning(`${t('System Error')}, ${res.data.message}`);
        return;
      }
      const {list, pageInfo} = data;
      setDataGridData((prevState) => ({
        cachedPagedRows: Object.assign(
          {...prevState.cachedPagedRows},
          {[pageIndex]: list},
        ),
        apiPageInfo:
          prevState.apiPageInfo.totalRows === 0
            ? {pageSize: pageInfo.pageSize, totalRows: pageInfo.totalRows}
            : {...prevState.apiPageInfo},
      }));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!dataGridData.cachedPagedRows[currentPageIndex]) {
      fetchFarmInvestInfoList(dataSource, currentPageIndex, filterDiscordId);
      // setIsLoading(true);
      // fetchFarmInvestInfoList(dataSource, currentPageIndex, filterDiscordId).then((res) => {
      //   const data = res.data.data;
      //   if (!data) {
      //     toast.warning(`${t('System Error')}, ${res.data.message}`);
      //     return;
      //   }
      //   // !data.coinInfo && toast.warning(`You Have No Coin Info`);
      //   // !data.investInfo && toast.warning(`You Have No Invest Info`);
      //   // setCoinInfo(Object.assign(data.coinInfo, {hcnInfo: data?.hcnInfo}));
      //   const {list, pageInfo} = data;
      //   setDataGridData((prevState) => ({
      //     cachedPagedRows: Object.assign(
      //       {...prevState.cachedPagedRows},
      //       {[currentPageIndex]: list},
      //     ),
      //     apiPageInfo:
      //       prevState.apiPageInfo.totalRows === 0
      //         ? {pageSize: pageInfo.pageSize, totalRows: pageInfo.totalRows}
      //         : {...prevState.apiPageInfo},
      //   }));
      //   setIsLoading(false);
      // });
    }
  }, [currentPageIndex]);

  useEffect(() => {
    resetDataGridStates();
    setDataGridCurrentPageNum(0);
    fetchFarmInvestInfoList(dataSource, 1, filterDiscordId);
  }, [filterDiscordId]);

  return (
    <>
      <DataGrid
        loading={isLoading}
        onPageChange={(pageNum) => setDataGridCurrentPageNum(pageNum)}
        columns={memoizedColumns}
        page={dataGridCurrentPageNum}
        rows={congregatedCachedRows}
        pageSize={dataGridPageSize}
        rowCount={filterDiscordId ? undefined : apiTotalRows}
        onRowClick={({row}) => {
          setRow(row);
          setDetailOpen(true);
        }}
        disableSelectionOnClick
        disableColumnMenu
        autoHeight
        components={{
          BaseTooltip: Tooltip,
        }}
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-row': {
            ':hover': {
              cursor: 'pointer',
            },
          },
        }}
        disableDensitySelector
        // experimentalFeatures={{ newEditingApi: true }}
      />
      <TransitionsModal open={detailOpen} onClose={() => setDetailOpen(false)}>
        {DetailPage(row)}
      </TransitionsModal>
    </>
  );
};

const DetailPage = (data) => {
  // const column = [
  //   {name: 'id', key: 'id'},
  //   {name: 'currency', key: 'currency'},
  //   {name: 'receivedAmount', key: 'receivedAmount'},
  //   {name: 'matchMark', key: 'matchMark'},
  //   {name: 'matchResult', key: 'matchResult'},
  //   {name: 'amountDiff', key: 'amountDiff'},
  //   {name: 'farmId', key: 'farmId'},
  //   {name: 'accountCode', key: 'accountCode'},
  //   {name: 'memberId', key: 'memberId'},
  //   {name: 'discord', key: 'discord'},
  //   {name: 'sendAmount', key: 'sendAmount'},
  //   {name: 'investorName', key: 'investorName'},
  //   {name: 'payer', key: 'payer'},
  //   {name: 'payee', key: 'payee'},
  //   {name: 'receiveLast4', key: 'receiveLast4'},
  //   {name: 'proof', key: 'proof'},
  //   {name: 'notesInvestor', key: 'notesInvestor'},
  //   {name: 'receivedDate', key: 'receivedDate'},
  //   {name: 'notesPayee', key: 'notesPayee'},
  //   {name: 'sendDate', key: 'sendDate'},
  //   {name: 'remark', key: 'remark'},
  //   {name: 'createTime', key: 'createTime'},
  //   {name: 'updateTime', key: 'updateTime'},
  //   {name: 'extend', key: 'extend'},
  // ];

  const column = InvestInfoColumnNamesFull.map((item) => ({
    name: item.name,
    key: item.name,
  }));

  if (!data) {
    return <div></div>;
  }

  return (
    <Box
      sx={{
        width: '80vw',
      }}
    >
      <Typography
        component={'h1'}
        sx={{
          fontSize: 24,
          fontWeight: 500,
        }}
      >
        Details
      </Typography>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{xs: 2, sm: 2, md: 6}}
        sx={{mt: 2}}
      >
        {column.map(({name, key}) => {
          return (
            <Grid key={key} item xs={6} md={6} sx={{alignSelf: 'center'}}>
              <Box
                component={'div'}
                sx={{display: 'flex', justifyContent: 'space-between'}}
              >
                <Box component={'span'} sx={{fontSize: 18, fontWeight: 500}}>
                  {name}
                </Box>
                <Box component={'span'} sx={{fontSize: 18, fontWeight: 500}}>
                  {data[key]}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

enum ValueType {
  Number = 'NUMBER',
  Date = 'Date',
}

interface ColumnField {
  name: string;
  format?: ValueType;
  width?: number; // data grid cell width
}

const InvestInfoColumnNames: ColumnField[] = [
  {name: 'discord', width: 160},
  {name: 'investorName', width: 140},
  {name: 'accountCode', width: 140},
  {name: 'sendAmount', width: 140, format: ValueType.Number},
  {name: 'currency', width: 140},
  {name: 'sendDate', width: 140},
  {name: 'receivedDate', width: 140},
  {name: 'payer', width: 140},
  {name: 'payee', width: 140},
  {name: 'receivedAmount', width: 140, format: ValueType.Number},
];

const InvestInfoColumnNamesFull: ColumnField[] = [
  {name: 'id'},
  {name: 'discord', width: 160},
  {name: 'memberId'},
  {name: 'farmId'},
  {name: 'currency'},
  {name: 'sendAmount', format: ValueType.Number},
  {name: 'amountDiff', format: ValueType.Number},
  {name: 'investorName'},
  {name: 'sendDate'},
  {name: 'receivedDate'},
  {name: 'receiveLast4'},
  {name: 'notesInvestor'},
  {name: 'matchMark'},
  {name: 'matchResult'},
  {name: 'notesPayee'},
  {name: 'receivedAmount', format: ValueType.Number},
  {name: 'accountCode'},
  {name: 'payer'},
  {name: 'payee'},
  {name: 'proof'},
  {name: 'createTime', format: ValueType.Date},
  {name: 'updateTime', format: ValueType.Date},
  {name: 'remark'},
];

const valueFormatterFn = (value?: string, format?: ValueType) => {
  if (value != null && value !== '') {
    switch (format) {
      case ValueType.Date:
        const conversionMultiplyer = `${value}`.length < 13 ? 1000 : 1;
        return new Date(parseInt(value) * conversionMultiplyer)
          .toISOString()
          .split('T')[0];
      case ValueType.Number:
        return parseInt(value).toLocaleString('en-US');
      case undefined:
        return value;
      default:
        return value;
    }
  }
  return '';
};
