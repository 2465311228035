import {useEffect, useMemo} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';

// Mui
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  Typography,
  Theme,
} from '@material-ui/core';

import {makeStyles} from '@material-ui/styles';
import {v4 as uuid} from 'uuid';

// Icons
import {
  User as UserIcon,
  // AlertCircle as AlertCircleIcon,
  // BarChart as BarChartIcon,
  // Lock as LockIcon,
  // Settings as SettingsIcon,
  // ShoppingBag as ShoppingBagIcon,
  // UserPlus as UserPlusIcon,
  // Users as UsersIcon,
  // DollarSign as DollarSignIcon,
} from 'react-feather';

// i18n
import {useTranslation} from 'react-i18next';

// import NavItem from './NavItem';
// import NavItemSub from './NavItemSub';
import {NavItem, NavItemPropsList} from './NavMenu/NavItem'; // New NavItem Component

// Router
import {useNavigate} from 'react-router-dom';

// Material Icons
import {
  // Person as UserIcon,
  MonetizationOnOutlined as CoinIcon,
  SavingsOutlined as InvestmentIcon,
  Gite as FarmIcon,
} from '@mui/icons-material';

// Redux
import {RootState} from '../store/store';
import {useSelector} from 'react-redux';
import {IRoles} from '../store/loginUserInfoSlice';

// const user = {
//   avatar: '/static/images/avatars/avatar_6.png',
//   jobTitle: 'Administrator',
//   name: 'Katarina Smith',
// };

// Auth0
import {useAuth0} from '@auth0/auth0-react';

const useStyle = makeStyles((theme: Theme) => {
  return {
    left1: {
      //   display: 'none',
      '@media (min-width:1200px)': {
        // display: 'block',
      },
    },
    left2: {
      display: 'none',
      '@media (min-width:1200px)': {
        display: 'block',
      },
    },
  };
});

const DashboardSidebar = ({onMobileClose, openMobile}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation();
  const classes = useStyle();
  const discordUserInfo = useSelector(
    (state: RootState) => state.user.discordUserInfo,
  );

  const {
    user,
    // isAuthenticated,
    isLoading,
    // loginWithRedirect,
    // logout,
    // getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    !isLoading && console.log(user);
  }, [isLoading]);

  const roles =
    user?.['x-nfscdb/roles']?.split(',').map((str) => str.trim()) || [];

  const menuItems: NavItemPropsList = useMemo(
    () =>
      roles.includes(IRoles.MANAGER)
        ? [
            {
              title: t('My Profile'),
              Icon: UserIcon,
              selected: location.pathname === '/app/profile',
              onClick: () => navigate('/app/profile'),
            },
            {
              title: t('Farm Information'),
              Icon: FarmIcon,
              subMenuOptions: [
                {
                  title: t('Coin'),
                  Icon: CoinIcon,
                  selected: location.pathname === '/app/farmInfo/coin',
                  onClick: () => navigate('/app/farmInfo/coin'),
                },
                {
                  title: t('Investment'),
                  Icon: InvestmentIcon,
                  selected: location.pathname === '/app/farmInfo/invest',
                  onClick: () => navigate('/app/farmInfo/invest'),
                },
              ],
            },
          ]
        : [
            {
              title: t('My Profile'),
              Icon: UserIcon,
              selected: location.pathname === '/app/profile',
              onClick: () => navigate('/app/profile'),
            },
          ],
    [roles],
  );

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <Avatar
          data-id="pppppp"
          // component={RouterLink}
          // src={discordUserInfo.avatar}
          src={user?.picture}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64,
            mb: 1,
          }}
          // to="/app/profile"
        />
        <Typography color="textPrimary" variant="h5">
          {/* {discordUserInfo.nickname} */}
          {'ID: ' + (user?.['x-nfscdb/user_id'] || user?.sub)?.split('|').pop()}
        </Typography>
        <Typography color="textPrimary" variant="h5">
          {/* {discordUserInfo.nickname} */}
          {user?.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {/* {discordUserInfo.discord} */}
          {user?.['x-nfscdb/discord']}
        </Typography>
      </Box>
      <Divider />
      {/* <Box sx={{p: 2}}> */}
      <Box>
        <List>
          {menuItems?.map((itemProps) => (
            <NavItem key={uuid()} {...itemProps} />
          ))}
        </List>
      </Box>
      <Box sx={{flexGrow: 1}} />
    </Box>
  );

  return (
    <>
      <div className={classes.left1}>
        {/* lgUp mobile */}
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </div>
      <div className={classes.left2}>
        {/* xlDown mobile */}
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)',
            },
          }}
        >
          {content}
        </Drawer>
      </div>
    </>
  );
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
