import axios from 'axios';
import {ApiResponse, UserInfo, CoinInfo, InvestInfo, PageInfo} from './types';

/** Discord OAuth Url */
export const DISCORD_AUTH_URL = `https://discordapp.com/api/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&scope=identify email&state=discord&redirect_uri=${process.env.REACT_APP_DISCORD_REDIRECT_URI}`;

/** Currently in use */

type PersonalCoinInfoApiRes = ApiResponse<{
  coinInfo: CoinInfo;
  hcnInfo: number;
}>;

type InvestInfoListRes = ApiResponse<{
  list: InvestInfo[];
  pageInfo: PageInfo;
}>;
type PersonalInvestInfoDetailRes = ApiResponse<InvestInfo>;

type AllFarmMemberCoinInfoApiResponse = ApiResponse<{
  list: CoinInfo[];
  pageInfo: PageInfo;
}>;
type AllFarmMemberInvestInfoApiResponse = ApiResponse<{
  list: InvestInfo[];
  pageInfo: PageInfo;
}>;

type FarmMemberListApiResponse = ApiResponse<{
  list: UserInfo[];
  pageInfo: PageInfo;
}>;

// export const queryFarmMemberAccountInfo = async (
//   params: FarmMemberAccountInfoApiParams,
//   accessToken: string,
// ) => {
//   return axios.get<FarmMemberAccountInfoApiResponse>(
//     '/api/detail/getDetailInfo',
//     {
//       params: params,
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     },
//   );
// };

export enum DataSource {
  original = 'original',
  control = 'control',
}

export const queryFarmMemberList = async (accessToken: string) => {
  return axios.get<FarmMemberListApiResponse>('/api/user/queryFarmUsers', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const queryPersonalCoinInfo = async (accessToken: string) => {
  return axios.get<PersonalCoinInfoApiRes>('/api/personal/coin', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const queryPersonalInvestInfoList = async (
  pageIndex: number,
  source: DataSource,
  accessToken: string,
) => {
  return axios.get<InvestInfoListRes>(`/api/personal/investments/${source}`, {
    params: {
      pageIndex: pageIndex,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const queryPersonalInvestInfoDetail = async (
  id: number,
  source: DataSource,
  accessToken: string,
) => {
  return axios.get<PersonalInvestInfoDetailRes>(
    `/api/personal/investments/${source}/detail/${id}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

export const queryAllFarmMembersCoinInfo = async (
  pageIndex: number,
  accessToken: string,
  discord?: string,
) => {
  return axios.get<AllFarmMemberCoinInfoApiResponse>(
    // '/api/coininfo/getFarmCoinInfo',
    '/api/farm/coin',
    {
      params: {
        pageIndex: pageIndex,
        discord: discord,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

export const queryAllFarmMembersInvestInfo = async (
  pageIndex: number,
  source: DataSource,
  accessToken: string,
  discord?: string,
) => {
  return axios.get<InvestInfoListRes>(`/api/farm/investments/${source}`, {
    params: {
      pageIndex: pageIndex,
      discord: discord,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// export const queryAllFarmMembersInvestInfo = async (
//   pageIndex: number,
//   accessToken: string,
// ) => {
//   return axios.get<AllFarmMemberInvestInfoApiResponse>(
//     // '/api/invest/getFarmInvestInfo',
//     '/api/farm/investments',
//     {
//       params: {pageIndex: pageIndex},
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     },
//   );
// };

export const validateDiscordUserIdentity = async (userId, email) => {
  return axios.get<ApiResponse<null>>('/api/user/setPrimaryEmail', {
    params: {
      userId: userId,
      email: email,
    },
  });
  // return axios({
  //   method: 'get',
  //   url: '/api/user/setPrimaryEmail',
  //   params: params,
  // });
};

/** Currently not used */

export const adminLogin = async (username: string, password: string) => {
  return axios({
    method: 'get',
    url: '/user/login',
    params: {
      username: username,
      password: password,
    },
  });
};

export const logout = async (username: string, loginMode: 0 | 1) => {
  const usernameParam =
    loginMode === 0 ? {username: username} : {discord: username};
  return axios({
    method: 'get',
    url: '/user/logout',
    params: {
      ...usernameParam,
      loginMode: loginMode,
    },
  });
};

export const queryFarms = async () => {
  return axios({
    method: 'get',
    url: '/api/user/queryFarms',
  });
};

export const queryAdmins = async () => {
  return axios({
    method: 'get',
    url: '/api/user/queryManagers',
  });
};

export const queryPersonalInvestment = async () => {
  return axios({
    method: 'get',
    url: '/api/detail/getPersonDetailInfo',
  });
};

export const testQueryPersonalInvestment = async (accessToken) => {
  return axios({
    method: 'get',
    url: '/api/detail/getPersonDetailInfo',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// Auth0 Test

export const testFetchLocalApi = async (accessToken) => {
  return axios({
    method: 'get',
    url: '/api/auth',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const testResetPassword = async () => {
  return axios({
    method: 'post',
    url: `https://nfsc.us.auth0.com/dbconnections/change_password`,
    headers: {'content-type': 'application/json'},
    data: {
      client_id: 'fb71wczl4fnKJEAXetP0PtDsWQUpnnRj',
      email: 'richard@gcoder.io',
      connection: 'testdb',
    },
  });
};
