import React, {useEffect, useState} from 'react';
import Zendesk from 'react-zendesk';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';

type Props = {};
const setting = {
  color: {
    theme: '#000',
  },
  launcher: {
    chatLabel: {
      'en-US': 'Need Help',
    },
  },
  contactForm: {
    fields: [{id: 'description', prefill: {'*': 'My pre-filled description'}}],
  },
};

export default function ZendeskComp({}: Props) {
  // const { isAuthenticated, isLoading } = useAuth0();

  // const [displayZendesk, setDisplayZendesk] = useState<boolean>(false)

  // useEffect(() => {
  //   if(!isLoading) {
  //     setDisplayZendesk(true)
  //   }
  //   console.log(isLoading)
  // }, [isLoading])

  return (
    <Zendesk
      defer
      zendeskKey={process.env.REACT_APP_ZENDESK_KEY}
      {...setting}
      onLoaded={() => console.log('zendesk is loaded')}
    />
  );
}
