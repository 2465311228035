import {stringify} from 'qs';

export const constants = {
  queryFarms: () => `/api/user/queryFarms`,
  queryAdmins: ({params}) => `/api/user/queryManagers` + stringify(params),
  queryFarmMemberAccountInfo: (params) =>
    `/api/detail/getDetailInfo?` + stringify(params),
  // queryFarmMemberAccountInfo: () => `/detail/getDetailInfo`,
  logout: () => `/api/user/logout`,
  queryPersonalInvestment: () => `/api/detail/getPersonDetailInfo`,
  adminLogin: ({params}) => {
    return {
      url: `/api/user/login`,
      method: 'POST',
      body: params,
    };
  },
};
