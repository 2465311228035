import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import {toast} from 'react-toastify';
import {InvestInfo, PageInfo} from '../../api/types';
import lodash from 'lodash';

// Component
import {InvestInfoDataGrid} from '../../components/datagrid/InvestInfoDataGrid';

import TransitionsModal from '../../components/TransitionsModal';
// Redux
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuid} from 'uuid';
// import {
//   LoginUserType,
//   selectLoginUserType,
//   setAdminLogout,
//   selectDiscordUserInfo,
//   setDiscordUserLogout,
// } from '../../store/loginUserInfoSlice';

import {styled} from '@mui/material/styles';
// Mui
import {
  Paper,
  Box,
  Grid,
  TextField,
  Autocomplete,
  createFilterOptions,
  Typography,
  List,
  Menu,
  ListItemButton,
  ListItemText,
  ListItem,
  Collapse,
  CardContent,
  Tooltip,
  Theme,
  ListItemIcon,
  Divider,
} from '@mui/material';
import {makeStyles} from '@material-ui/styles';
import {DataGrid, GridColDef, GridValueGetterParams} from '@mui/x-data-grid';

// Icons
import {
  MonetizationOn as CoinIcon,
  CurrencyBitcoin as BitCoinIcon,
  SavingsOutlined as InvestmentIcon,
  Gite as FarmIcon,
  AccountBox as AccountIcon,
  Badge as BadgeIcon,
  Email as EmailIcon,
  Calculate as CalculateIcon,
  Lock as LockIcon,
  ConfirmationNumber as IdIcon,
  Event as CalendarCreateIcon,
  EventAvailable as CalendarUpdateIcon,
  Savings as PiggyBankIcon,
  SpeakerNotes as NotesIcon,
} from '@mui/icons-material';

import {Icon} from 'react-feather';

// API
import {
  // queryFarmMemberAccountInfo,
  // queryAllFarmMembersInvestInfo,
  // queryAllFarmMembersCoinInfo,
  // testQueryPersonalInvestment,
  queryPersonalCoinInfo,
  queryPersonalInvestInfoList,
  queryPersonalInvestInfoDetail,
  DataSource,
} from '../../api';
import {useQueryFarmMemberAccountInfoQuery} from '../../api/all';

// Router
import {useNavigate, useLocation} from 'react-router-dom';

// i18n
import {useTranslation} from 'react-i18next';

// Auth0
import {useAuth0} from '@auth0/auth0-react';

// const useStyle = makeStyles((theme: Theme) => {
//   return {
//     root: {
//       width: '100%',
//       height: '100%',
//       display: 'flex',
//     },
//     paper: {
//       flex: 1,
//     },
//   };
// });

// interface DataGridDataState {
//   cachedPagedRows: {
//     [key: number]: InvestInfo[]; // key is pageIndex
//   };
//   apiPageInfo: Partial<PageInfo>;
// }

export const ProfileInfoGrid = () => {
  // const classes = useStyle();
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const location = useLocation();
  // const [row, setRow] = useState(null);
  const {user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims} =
    useAuth0();

  const {t, i18n} = useTranslation();

  // const discordUserInfo = useSelector(selectDiscordUserInfo);

  // useEffect(() => {
  //   console.log('user info')
  //   console.log(user)
  //   // getIdTokenClaims().then(res => console.log(res))
  // }, [user])

  /** Data grid states */
  // const [dataGridData, setDataGridData] = useState<DataGridDataState>({
  //   cachedPagedRows: {}, // loaded page row data
  //   apiPageInfo: {
  //     pageSize: 50,
  //     totalRows: 0,
  //   },
  // });
  // const [dataGridCurrentPageNum, setDataGridCurrentPageNum] =
  //   useState<number>(0);
  // const dataGridPageSize = 10;

  /** Collect all rows in cachedPagedRows into a single array */
  // const congregatedCachedRows = useMemo(() => {
  //   const rows = [] as InvestInfo[];
  //   for (let key of Object.keys(dataGridData.cachedPagedRows)) {
  //     rows.push(...dataGridData.cachedPagedRows[key]);
  //   }
  //   return rows;
  // }, [dataGridData]);

  // const {pageSize: apiPageSize, totalRows: apiTotalRows} =
  //   dataGridData.apiPageInfo;

  /** Calculate which api page index we're on based on data grid's page number
   *  (Since api page size is different from data grid's page size)
   */
  // const currentPageIndex = useMemo(
  //   () =>
  //     // DataGrid pageNum is zero-indexed
  //     Math.ceil(
  //       ((dataGridCurrentPageNum + 1) * dataGridPageSize) / apiPageSize,
  //     ),
  //   [dataGridCurrentPageNum, dataGridPageSize, apiPageSize],
  // );

  /** Coin info state */
  const [coinInfo, setCoinInfo] = useState<object>({});

  /** Generate column headers */
  // const memoizedColumns = useMemo(() => {
  //   const result: GridColDef[] = InvestInfoColumnNames.map((field) => ({
  //     field: field.name,
  //     headerName: lodash.startCase(t(field.name)),
  //     width: field.width || 120,
  //     sortable: false,
  //     headerAlign: 'center',
  //     align: 'center',
  //     renderCell: (params) => (
  //       <Tooltip title={params.formattedValue}>
  //         <div className="MuiDataGrid-cellContent">
  //           {' '}
  //           {params.formattedValue}{' '}
  //         </div>
  //       </Tooltip>
  //     ),
  //     valueFormatter: (params) => valueFormatterFn(params.value, field.format),
  //   }));
  //   return result;
  // }, [InvestInfoColumnNames, t, i18n]);

  // Loading states
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  // const fetchFarmMemberInfoWithToken = async () => {
  //   const accessToken = await getAccessTokenSilently({
  //     // audience: 'https://nfscdb-app.gnewsapp.com/api/',
  //     audience: `${process.env.REACT_APP_AUTH0_API_AUDIENCE}`,
  //   });
  //   const claims = await getIdTokenClaims();
  //   const discord = claims['x-nfscdb/discord'];
  //   return queryFarmMemberAccountInfo(
  //     {
  //       // discord: 'YandereBot13#1626',
  //       discord: discord,
  //       pageIndex: currentPageIndex,
  //     },
  //     accessToken,
  //   );
  // };

  // const fetchPersonalInvestInfoList = async (source: DataSource, pageIndex) => {
  //   const accessToken = await getAccessTokenSilently({
  //     // audience: 'https://nfscdb-app.gnewsapp.com/api/',
  //     audience: `${process.env.REACT_APP_AUTH0_API_AUDIENCE}`,
  //   });
  //   // const claims = await getIdTokenClaims();
  //   // const discord = claims['x-nfscdb/discord'];
  //   return queryPersonalInvestInfoList(
  //     pageIndex,
  //     source,
  //     accessToken,
  //   );
  // };

  const fetchPersonalCoinInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: `${process.env.REACT_APP_AUTH0_API_AUDIENCE}`,
    });
    return queryPersonalCoinInfo(accessToken);
  };

  /** Fetch personal coin info */
  useEffect(() => {
    fetchPersonalCoinInfo().then((res) => {
      const data = res?.data?.data;
      setCoinInfo(Object.assign(data?.coinInfo, {hcnInfo: data?.hcnInfo}));
    });
  }, []);

  // useEffect(() => {
  //   if (!dataGridData.cachedPagedRows[currentPageIndex]) {
  //     setIsLoading(true);
  //     fetchPersonalInvestInfoList(DataSource.original, currentPageIndex).then((res) => {
  //       const data = res.data.data;
  //       if (!data) {
  //         toast.warning(`${t('System Error')}, ${res.data.message}`);
  //         return;
  //       }
  //       // !data.coinInfo && toast.warning(`You Have No Coin Info`);
  //       // !data.investInfo && toast.warning(`You Have No Invest Info`);
  //       // setCoinInfo(Object.assign(data.coinInfo, {hcnInfo: data?.hcnInfo}));
  //       const {list, pageInfo} = data;
  //       setDataGridData((prevState) => ({
  //         cachedPagedRows: Object.assign(
  //           {...prevState.cachedPagedRows},
  //           {[currentPageIndex]: list},
  //         ),
  //         apiPageInfo:
  //           prevState.apiPageInfo.totalRows === 0
  //             ? {pageSize: pageInfo.pageSize, totalRows: pageInfo.totalRows}
  //             : {...prevState.apiPageInfo},
  //       }));
  //       setIsLoading(false);
  //     });
  //   }
  // }, [currentPageIndex]);

  return (
    <>
      <Box
        sx={(theme) => ({
          // minHeight: '100%',
          minHeight: 'calc(100% - 64px)',
          display: 'flex',
          gap: 4,
          mx: 4,
          my: 4,
          flexDirection: 'column',
          // [theme.breakpoints.down('lg')]: {
          //   height: '100%',
          // },
        })}
      >
        <Paper sx={{flex: 2, px: 4, py: 6}}>
          <Typography variant="h4" sx={{color: 'black', fontWeight: 'bold'}}>
            {t('Coin Info')}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <List sx={{my: 2, overflowY: 'auto'}}>
                {CoinInfoRowNames.slice(0, CoinInfoMidPointIndex).map(
                  (item, index) => (
                    <Fragment key={uuid()}>
                      <ListItem>
                        <ListItemIcon sx={{minWidth: 'unset', mr: '6px'}}>
                          {item.Icon && <item.Icon />}
                        </ListItemIcon>
                        <ListItemText>
                          {lodash.startCase(t(item.name))}
                        </ListItemText>
                        <ListItemText sx={{ml: 6, textAlign: 'right'}}>
                          {valueFormatterFn(coinInfo[item.name], item.format)}
                        </ListItemText>
                      </ListItem>
                      {index < CoinInfoRowNames.length - 1 && (
                        <Divider sx={{mx: 2}} />
                      )}
                    </Fragment>
                  ),
                )}
              </List>
            </Grid>
            <Grid item xs={6}>
              <List sx={{my: 2, overflowY: 'auto'}}>
                {CoinInfoRowNames.slice(CoinInfoMidPointIndex).map(
                  (item, index) => (
                    <Fragment key={uuid()}>
                      <ListItem>
                        <ListItemIcon sx={{minWidth: 'unset', mr: '6px'}}>
                          {item.Icon && <item.Icon />}
                        </ListItemIcon>
                        <ListItemText>
                          {lodash.startCase(t(item.name))}
                        </ListItemText>
                        <ListItemText sx={{ml: 6, textAlign: 'right'}}>
                          {valueFormatterFn(coinInfo[item.name], item.format)}
                        </ListItemText>
                      </ListItem>
                      {index < CoinInfoRowNames.length - 1 && (
                        <Divider sx={{mx: 2}} />
                      )}
                    </Fragment>
                  ),
                )}
              </List>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{flex: 5, px: 4, py: 6}}>
          <Typography variant="h4" sx={{color: 'black', fontWeight: 'bold'}}>
            {t('Investment Info Control')}
          </Typography>
          <Box sx={{mt: '24px'}}>
            <InvestInfoDataGrid
              dataSource={DataSource.control}
              fetcherApi={queryPersonalInvestInfoList}
            />
          </Box>
        </Paper>
        <Paper sx={{flex: 5, px: 4, py: 6}}>
          <Typography variant="h4" sx={{color: 'black', fontWeight: 'bold'}}>
            {t('Investment Info Original')}
          </Typography>
          <Box sx={{mt: '24px'}}>
            <InvestInfoDataGrid
              dataSource={DataSource.original}
              fetcherApi={queryPersonalInvestInfoList}
            />
            {/* <DataGrid
              loading={isLoading}
              onPageChange={(pageNum) => setDataGridCurrentPageNum(pageNum)}
              columns={memoizedColumns}
              rows={congregatedCachedRows}
              pageSize={dataGridPageSize}
              rowCount={apiTotalRows}
              onRowClick={({row}) => {
                setRow(row);
              }}
              disableSelectionOnClick
              disableColumnMenu
              autoHeight
              components={{
                BaseTooltip: Tooltip,
              }}
              sx={{
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row': {
                  ':hover': {
                    cursor: 'pointer',
                  },
                },
              }}
              disableDensitySelector
              // experimentalFeatures={{ newEditingApi: true }}
            /> */}
          </Box>
        </Paper>
      </Box>
      {/* <TransitionsModal
        open={row === null ? false : true}
        onClose={() => {
          setRow(null);
        }}
      >
        {DetailPage(row)} */}
      {/* <Typography id="transition-modal-title" variant="h6" component="h2">
          Text in a modal
        </Typography>
        <Typography id="transition-modal-description" sx={{mt: 2}}>
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </Typography> */}
      {/* </TransitionsModal> */}
    </>
  );
};

// const DetailPage = (data) => {
//   const column = [
//     {name: 'id', key: 'id'},
//     {name: 'currency', key: 'currency'},
//     {name: 'receivedAmount', key: 'receivedAmount'},
//     {name: 'matchMark', key: 'matchMark'},
//     {name: 'matchResult', key: 'matchResult'},
//     {name: 'amountDiff', key: 'amountDiff'},
//     {name: 'farmId', key: 'farmId'},
//     {name: 'accountCode', key: 'accountCode'},
//     {name: 'memberId', key: 'memberId'},
//     {name: 'discord', key: 'discord'},
//     {name: 'sendAmount', key: 'sendAmount'},
//     {name: 'investorName', key: 'investorName'},
//     {name: 'payer', key: 'payer'},
//     {name: 'payee', key: 'payee'},
//     {name: 'receiveLast4', key: 'receiveLast4'},
//     {name: 'proof', key: 'proof'},
//     {name: 'notesInvestor', key: 'notesInvestor'},
//     {name: 'receivedDate', key: 'receivedDate'},
//     {name: 'notesPayee', key: 'notesPayee'},
//     {name: 'sendDate', key: 'sendDate'},
//     {name: 'remark', key: 'remark'},
//     {name: 'createTime', key: 'createTime'},
//     {name: 'updateTime', key: 'updateTime'},
//     {name: 'extend', key: 'extend'},
//   ];

//   if (!data) {
//     return <div></div>;
//   }

//   return (
//     <Box
//       sx={{
//         width: '80vw',
//       }}
//     >
//       <Typography
//         component={'h1'}
//         sx={{
//           fontSize: 24,
//           fontWeight: 500,
//         }}
//       >
//         Details
//       </Typography>
//       <Grid
//         container
//         rowSpacing={2}
//         columnSpacing={{xs: 2, sm: 2, md: 6}}
//         sx={{mt: 2}}
//       >
//         {column.map(({name, key}) => {
//           return (
//             <Grid key={key} item xs={6} md={6} sx={{alignSelf: 'center'}}>
//               <Box
//                 component={'div'}
//                 sx={{display: 'flex', justifyContent: 'space-between'}}
//               >
//                 <Box component={'span'} sx={{fontSize: 18, fontWeight: 500}}>
//                   {name}
//                 </Box>
//                 <Box component={'span'} sx={{fontSize: 18, fontWeight: 500}}>
//                   {data[key]}
//                 </Box>
//               </Box>
//             </Grid>
//           );
//         })}
//       </Grid>
//     </Box>
//   );
// };

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

enum ValueType {
  Number = 'NUMBER',
  Date = 'Date',
}

interface ColumnField {
  name: string;
  format?: ValueType;
  width?: number; // data grid cell width
}

interface RowField extends ColumnField {
  Icon?: Icon;
}

const CoinInfoRowNames: RowField[] = [
  {name: 'discord', Icon: AccountIcon},
  {name: 'memberId', Icon: BadgeIcon},
  {name: 'farmId', Icon: FarmIcon},
  {name: 'email', Icon: EmailIcon},
  {name: 'hcnInfo', Icon: CoinIcon, format: ValueType.Number},
  {name: 'investHcn', Icon: CoinIcon, format: ValueType.Number},
  {name: 'benefitHcn', Icon: CoinIcon, format: ValueType.Number},
  {name: 'icoHcn', Icon: CoinIcon, format: ValueType.Number},
  {name: 'toFmv', Icon: LockIcon, format: ValueType.Number},
  {name: 'holdForOthers', Icon: PiggyBankIcon, format: ValueType.Number},
  {name: 'holdByOthers', Icon: PiggyBankIcon, format: ValueType.Number},
  {name: 'calculationHcn', Icon: CalculateIcon, format: ValueType.Number},
  {
    name: 'calculationHoldByOthers',
    Icon: CalculateIcon,
    format: ValueType.Number,
  },
  {
    name: 'calculationHoldForOthers',
    Icon: CalculateIcon,
    format: ValueType.Number,
  },
  {name: 'id', Icon: IdIcon},
  {name: 'createTime', Icon: CalendarCreateIcon, format: ValueType.Date},
  {name: 'updateTime', Icon: CalendarUpdateIcon, format: ValueType.Date},
  {name: 'remark', Icon: NotesIcon},
];

const CoinInfoMidPointIndex = Math.ceil(CoinInfoRowNames.length / 2);

// const InvestInfoColumnNames: ColumnField[] = [
//   {name: 'discord', width: 160},
//   {name: 'memberId'},
//   {name: 'farmId'},
//   {name: 'currency'},
//   {name: 'sendAmount', format: ValueType.Number},
//   {name: 'amountDiff', format: ValueType.Number},
//   {name: 'investorName'},
//   {name: 'sendDate'},
//   {name: 'receivedDate'},
//   {name: 'receiveLast4'},
//   {name: 'notesInvestor'},
//   {name: 'matchMark'},
//   {name: 'matchResult'},
//   {name: 'notesPayee'},
//   {name: 'receivedAmount', format: ValueType.Number},
//   {name: 'accountCode'},
//   {name: 'payer'},
//   {name: 'payee'},
//   {name: 'proof'},
//   {name: 'id'},
//   {name: 'createTime', format: ValueType.Date},
//   {name: 'updateTime', format: ValueType.Date},
//   {name: 'remark'},
// ];

const valueFormatterFn = (value?: string, format?: ValueType) => {
  if (value != null && value !== '') {
    switch (format) {
      case ValueType.Date:
        const conversionMultiplyer = `${value}`.length < 13 ? 1000 : 1;
        return new Date(parseInt(value) * conversionMultiplyer)
          .toISOString()
          .split('T')[0];
      case ValueType.Number:
        return parseInt(value).toLocaleString('en-US');
      case undefined:
        return value;
      default:
        return value;
    }
  }
  return '';
};
