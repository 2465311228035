import {Helmet} from 'react-helmet';
import {Box, Button, Container, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

const NotFound = () => {
  const {t, i18n} = useTranslation();
  return (
    <>
      <Helmet>
        <title>404 | Material Kit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Container
          maxWidth="md"
          style={{display: 'flex', flexDirection: 'column'}}
        >
          <Typography align="center" color="textPrimary" variant="h1">
            404: The page you are looking for isn’t here
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation
          </Typography>
          <Box sx={{textAlign: 'center'}}>
            <img
              alt="Under development"
              src="/static/images/undraw_page_not_found_su7k.svg"
              style={{
                marginTop: 50,
                display: 'inline-block',
                maxWidth: '100%',
                width: 560,
              }}
            />
          </Box>
          <br />
          <br />
          <Link to="/app/profile" style={{alignSelf: 'center'}}>
            {t('Go back')}
          </Link>
        </Container>
      </Box>
    </>
  );
};

export default NotFound;
