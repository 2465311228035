import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {toast} from 'react-toastify';
import lodash from 'lodash';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuid} from 'uuid';

// Mui
import {
  Paper,
  Box,
  TextField,
  Autocomplete,
  createFilterOptions,
  Typography,
  List,
  Menu,
  ListItemButton,
  ListItemText,
  Collapse,
  Tooltip,
} from '@mui/material';
// import {DataGrid, GridColDef, GridValueGetterParams} from '@mui/x-data-grid';

// Components
import {InvestInfoDataGrid} from '../../components/datagrid/InvestInfoDataGrid';

// API & Types
import {
  // queryFarmMemberList,
  // queryFarmMemberAccountInfo,
  queryAllFarmMembersInvestInfo,
  queryAllFarmMembersCoinInfo,
  queryFarmMemberList,
  DataSource,
} from '../../api';
import {CoinInfo, InvestInfo, PageInfo} from '../../api/types';

// Router
import {useNavigate, useLocation} from 'react-router-dom';

// i18n
import {useTranslation} from 'react-i18next';

// Auth0
import {useAuth0} from '@auth0/auth0-react';

interface AutoCompleteInputFields {
  discord_original: string;
  discord_control: string;
}

interface AutoCompleteOptionsState {
  discordIdSet: Set<string>;
}

export const FarmInvestInfoGrids = () => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const location = useLocation();
  const {t, i18n} = useTranslation();

  const {
    // user,
    // isAuthenticated,
    // isLoading,
    // loginWithRedirect,
    // logout,
    getAccessTokenSilently,
  } = useAuth0();
  /** Auto complete input states */
  const [autoCompleteInputs, setAutoCompleteInputs] =
    useState<AutoCompleteInputFields>({
      discord_original: '',
      discord_control: '',
    });

  const [autoCompleteOptionsState, setAutoCompleteOptionsState] =
    useState<AutoCompleteOptionsState>({
      discordIdSet: new Set<string>(),
    });

  const fetchFarmMemberList = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: `${process.env.REACT_APP_AUTH0_API_AUDIENCE}`,
    });
    return queryFarmMemberList(accessToken);
  };

  // fetch farm user list
  useEffect(() => {
    fetchFarmMemberList().then((res) => {
      const list = res?.data?.data?.list || [];
      const discordIdList = list.map((item) => item.discord);
      setAutoCompleteOptionsState((prevState) => {
        const clonedDiscordIdSet = new Set<string>(prevState.discordIdSet);
        discordIdList.forEach((discordId) => clonedDiscordIdSet.add(discordId));
        return {
          ...prevState,
          discordIdSet: clonedDiscordIdSet,
        };
      });
    });
  }, []);

  return (
    <Box
      sx={(theme) => ({
        // minHeight: '100%',
        minHeight: 'calc(100% - 64px)',
        display: 'flex',
        gap: 4,
        mx: 4,
        my: 4,
        flexDirection: 'column',
        // [theme.breakpoints.down('md')]: {
        //   flexDirection: 'column',
        // },
      })}
    >
      <Paper
        // elevation={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 2,
          justifyContent: 'center',
          px: 4,
          py: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            minHeight: '500px',
          }}
        >
          <Box sx={{flex: 1}}>
            <Typography
              variant="h4"
              sx={{color: 'black', mb: '8px', fontWeight: 'bold'}}
            >
              {/* {currentSubMenuPath === SubMenuPaths.coin
                ? t('Farm Member Coin Info')
                : t('Farm Member Investment Info')} */}
              {t('Farm Member Investment Info Control')}
            </Typography>
            <Box
              sx={{margin: '10px 0px', display: 'flex', alignItems: 'center'}}
            >
              <Autocomplete
                disablePortal
                id="combo-box"
                options={Array.from(autoCompleteOptionsState.discordIdSet)}
                sx={{
                  minWidth: '25%',
                  margin: '14px 20px 14px 0px',
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Discord ID"
                    variant="outlined"
                    size="small"
                  />
                )}
                // inputValue={autoCompleteInputs.discord}
                onChange={(_, value) => {
                  // setDataGridCurrentPageNum(0);
                  setAutoCompleteInputs((prevState) => ({
                    ...prevState,
                    discord_control: value || '',
                  }));
                }}
              />
            </Box>
            <InvestInfoDataGrid
              dataSource={DataSource.control}
              filterDiscordId={autoCompleteInputs.discord_control}
              fetcherApi={queryAllFarmMembersInvestInfo}
            />
          </Box>
        </Box>
      </Paper>
      <Paper
        // elevation={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 2,
          justifyContent: 'center',
          px: 4,
          py: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            minHeight: '500px',
          }}
        >
          <Box sx={{flex: 1}}>
            <Typography
              variant="h4"
              sx={{color: 'black', mb: '8px', fontWeight: 'bold'}}
            >
              {/* {currentSubMenuPath === SubMenuPaths.coin
                ? t('Farm Member Coin Info')
                : t('Farm Member Investment Info')} */}
              {t('Farm Member Investment Info Original')}
            </Typography>
            <Box
              sx={{margin: '10px 0px', display: 'flex', alignItems: 'center'}}
            >
              <Autocomplete
                disablePortal
                id="combo-box"
                options={Array.from(autoCompleteOptionsState.discordIdSet)}
                sx={{
                  minWidth: '25%',
                  margin: '14px 20px 14px 0px',
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Discord ID"
                    variant="outlined"
                    size="small"
                  />
                )}
                // inputValue={autoCompleteInputs.discord}
                onChange={(_, value) => {
                  // setDataGridCurrentPageNum(0);
                  setAutoCompleteInputs((prevState) => ({
                    ...prevState,
                    discord_original: value || '',
                  }));
                }}
              />
            </Box>
            <InvestInfoDataGrid
              dataSource={DataSource.original}
              filterDiscordId={autoCompleteInputs.discord_original}
              fetcherApi={queryAllFarmMembersInvestInfo}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
