import {Navigate, Outlet} from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import DefaultLayout from './components/DefaultLayout';
import NotFound from './pages/NotFound';
// import MainLayout from './components/MainLayout';
// import Account from './pages/Account';
// import CustomerList from './pages/CustomerList';
// import Dashboard from './pages/Dashboard';
// import SignIn from './pages/SignIn';
// import {LoginMainPage} from './pages/Login';
// import ProductList from './pages/ProductList';
// import Register from './pages/Register';
// import Settings from './pages/Settings';
// import {DiscordAuthHandler} from './pages/Login/discordAuthHandler';
// import {UsernameLogin, USERNAME_LOGIN_PATH} from './pages/Login/UsernameLogin';

// import {AdminDataGrid} from './pages/FarmInfo/Admin (archived)';
import {ProfileInfoGrid} from './pages/MyProfile/Profile';
import {FarmInvestInfoGrids} from './pages/FarmInfo/InvestInfo';
import {FarmCoinInfoGrids} from './pages/FarmInfo/CoinInfo';
import {UserIdentityAuthenticationPage} from './pages/UserIdentityAuth';
import {AuthStatusBroker} from './pages/Login/AuthStatusBroker';

// Auth0
import {withAuthenticationRequired} from '@auth0/auth0-react';

const ProtectedComponent = ({component, ...propsForComponent}) => {
  const Cp = withAuthenticationRequired(component);
  return <Cp {...propsForComponent} />;
};

export const getRoutes = (isLoggedIn: boolean, isManager: boolean) => [
  {
    path: 'auth',
    element: <DefaultLayout />,
    children: [
      {path: 'discord', element: <UserIdentityAuthenticationPage />},
      // {path: 'identity', element: <UserIdentityAuthenticationPage />},
      {path: '*', element: <Navigate to="/404" />},
    ],
  },
  {
    path: '/',
    element: <DefaultLayout />,
    children: [
      // {path: 'signin', element: <SignIn />},
      // {path: 'login', element: isLoggedIn ? <Navigate to="/app/profile" /> : <RedirectToAuth0 />},
      // {path: 'login', element: <LoginMainPage />},
      // {path: 'login', element: <ProtectedComponent component={LoginMainPage} />},
      // {path: 'login', element: <RedirectToAuth0 />},
      // {path: 'register', element: <Register />},
      {path: '404', element: <NotFound />},
      {path: '/', element: <ProtectedComponent component={AuthStatusBroker} />},
      {path: '*', element: <Navigate to="/404" />},
    ],
  },
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      // {path: 'account', element: <Account />},
      // {path: 'customers', element: <CustomerList />},
      // {path: 'dashboard', element: <Dashboard />},
      // {path: 'products', element: <ProductList />},
      // {
      //   path: 'settings',
      //   element: isLoggedIn ? <Settings /> : <Navigate to="/login" />,
      // },
      {
        path: 'profile',
        element: <ProtectedComponent component={ProfileInfoGrid} />,
      },
      {
        path: 'farmInfo/coin',
        element: <ProtectedComponent component={FarmCoinInfoGrids} />,
      },
      {
        path: 'farmInfo/invest',
        element: <ProtectedComponent component={FarmInvestInfoGrids} />,
      },
      {path: '*', element: <Navigate to="/404" />},
    ],
  },
  // {
  //   path: 'login',
  //   element: <DefaultLayout />,
  //   children: [
  //     {path: 'username', element: <UsernameLogin />},
  //     {path: '*', element: <Navigate to="/404" />},
  //   ],
  // },
];
