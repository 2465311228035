import {useState} from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import {
  Icon,
  User as UserIcon,
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
} from 'react-feather';
import CurrencyPound from '../../icons/CurrencyPound';
import {makeStyles} from '@material-ui/styles';
import {Theme} from '@material-ui/core/styles';
import {v4 as uuid} from 'uuid';

const useStyle = makeStyles((theme: Theme) => {
  return {
    text: {
      '& span': {
        fontSize: 14,
      },
    },
    icon: {
      minWidth: 'initial',
      marginRight: '8px',
    },
    list_item_button: {
      padding: '10px 24px',
      borderRadius: '4px',
    },
    sublist_item_button: {
      padding: '8px 32px',
    },
  };
});

export type NavItemPropsList = NavItemProps[];

interface NavItemProps {
  title: string;
  Icon: Icon;
  selected?: boolean;
  subMenuOptions?: SubMenuItemOptions[];
  onClick?: () => void;
}

interface SubMenuItemOptions {
  title: string;
  Icon: Icon;
  selected?: boolean;
  onClick?: () => void;
}

export const NavItem: React.FC<NavItemProps> = ({
  Icon,
  title,
  selected,
  subMenuOptions,
  onClick,
  ...rest
}) => {
  const [open, setOpen] = useState(true);
  const classes = useStyle();
  const handleClick = () => {
    subMenuOptions && setOpen(!open);
    onClick && onClick();
  };
  return (
    <>
      <ListItemButton
        className={classes.list_item_button}
        onClick={handleClick}
        disableGutters
        selected={selected}
        {...rest}
      >
        <ListItemIcon className={classes.icon}>
          {/* <UserIcon /> */}
          <Icon />
        </ListItemIcon>
        <ListItemText className={classes.text} primary={title} />
        {subMenuOptions?.length > 0 ? (
          open ? (
            <ChevronUpIcon />
          ) : (
            <ChevronDownIcon />
          )
        ) : null}
      </ListItemButton>

      {subMenuOptions?.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {subMenuOptions.map((option) => (
            <List component="div" disablePadding key={uuid()}>
              <ListItemButton
                onClick={option.onClick}
                className={classes.sublist_item_button}
                selected={option.selected}
              >
                <ListItemIcon className={classes.icon}>
                  {/* <CurrencyPound /> */}
                  <option.Icon />
                </ListItemIcon>
                <ListItemText className={classes.text} primary={option.title} />
              </ListItemButton>
            </List>
          ))}
        </Collapse>
      )}
    </>
  );
};
