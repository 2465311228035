import React, {useEffect} from 'react';

// import {
//   DISCORD_AUTH_URL,
//   testFetchLocalApi,
//   testResetPassword,
//   testQueryPersonalInvestment,
// } from '../../api';

import {CircularProgress} from '@mui/material';

// Router
import {useNavigate, useLocation} from 'react-router-dom';
import {USERNAME_LOGIN_PATH} from './UsernameLogin';

// i18n
import {useTranslation} from 'react-i18next';

// Auth0
import {useAuth0} from '@auth0/auth0-react';

export const AuthStatusBroker = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {isLoading, isAuthenticated} = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/app/profile');
    }
  }, [isLoading]);

  return <CircularProgress />;
};

// export const LOGIN_MAIN_PAGE_PATH = '/login';

// const Copyright: React.FC<TypographyProps> = (props) => {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// };
