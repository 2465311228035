import React, {useEffect, useState, useCallback} from 'react';
// import CssBaseline from '@mui/material/CssBaseline';
// import axios from 'axios';

// Redux
// import {useDispatch, useSelector} from 'react-redux';
// import {selectDiscordUserInfo} from '../../store/loginUserInfoSlice';

// MUI
import {
  Box,
  Button,
  SvgIcon,
  Typography,
  TextField,
  Paper,
  Grid,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import {SupervisorAccount} from '@mui/icons-material';

// Components
import {toast} from 'react-toastify';
import {ReactComponent as DiscordLogo} from '../Login/discord_logo.svg';

// API
import {
  DISCORD_AUTH_URL,
  testFetchLocalApi,
  // testResetPassword,
  validateDiscordUserIdentity,
} from '../../api';

// Router
import {useNavigate, useLocation} from 'react-router-dom';

// i18n
import {useTranslation, Trans} from 'react-i18next';

// util
import lodash from 'lodash';

// Zendesk
import ZendeskComp from '../../components/zendesk/ZendeskComp';

// Auth0
// import {useAuth0} from '@auth0/auth0-react';

// import {
//   createTheme,
//   ThemeProvider,
//   makeStyles,
//   Theme,
// } from '@mui/material/styles';

export const UserIdentityAuthenticationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation();

  const steps = [
    t('find_your_account'),
    t('verify_your_identity'),
    t('receive_signup_email'),
  ];

  /** Auth0 Test code */
  // const {
  //   user,
  //   isAuthenticated,
  //   isLoading,
  //   loginWithRedirect,
  //   logout,
  //   getAccessTokenSilently,
  // } = useAuth0();

  const handleDiscordLogin = () => {
    window.open(DISCORD_AUTH_URL, '_self');
  };

  const [activeStep, setActiveStep] = React.useState(0);

  const [currentDiscordUserInfo, setCurrentDiscordUserInfo] = useState({
    Avatar: null,
    Discord: null,
    Nickname: null,
    UserId: null,
    emails: [],
  });

  const [emailInput, setEmailInput] = useState('');

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const data = JSON.parse(`${urlSearchParams.get('data')}`);
    const code = data?.code;
    // if (code !== 0) toast.warning('User not found')
    if (code !== 0) {
      toast.warning(t('user_not_found_notice'), {
        toastId: 'user_not_found_notice',
        autoClose: 20000,
      });
      return;
    }
    // console.log(res);
    const {Avatar, Discord, Nickname, UserId, emails} = data?.data || {};
    if (Discord && UserId && emails.length > 0) {
      setCurrentDiscordUserInfo({
        Avatar: Avatar,
        Discord: Discord,
        Nickname: Nickname,
        UserId: UserId,
        emails: emails,
      });
      setActiveStep(1);
    }
  }, []);

  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage:
          'url(/photo/ricardo-gomez-angel-otf25n2UETg-unsplash.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
      }}
    >
      <Paper
        sx={{
          width: '700px',
          minHeight: '400px',
          mx: 6,
          my: 8,
          px: 6,
          py: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          // justifyContent: 'center',
        }}
      >
        <Box sx={{height: '100%', width: '100%'}}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: {completed?: boolean} = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Typography sx={{mt: 2, mb: 1, mx: 1}}>
            {/* Step {activeStep + 1} */}
            {t('step', {currStep: activeStep + 1})}
          </Typography>
          <Box
            sx={{
              mt: 6,
              px: '8px',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {activeStep === 0 && (
              <>
                <Typography sx={{fontSize: 18}}>
                  {
                    // 'Sign in with your Discord account to find your associated ID and Email address'
                    t('discord_signin_prompt')
                  }
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: '#5865f2',
                    textTransform: 'none',
                    mt: '40px',
                  }}
                  startIcon={
                    <SvgIcon sx={{width: '40px', height: '40px'}}>
                      <DiscordLogo />
                    </SvgIcon>
                  }
                  onClick={handleDiscordLogin}
                >
                  <Typography variant="h6">
                    {t('Sign in with Discord')}
                  </Typography>
                </Button>
              </>
            )}
            {activeStep === 1 && (
              <>
                <Typography variant="h5">
                  {t('hello')} <strong>{currentDiscordUserInfo.Discord}</strong>
                  , {t('your_account_id_is') + ':'}
                  <strong>{currentDiscordUserInfo.UserId}</strong>{' '}
                </Typography>
                <Typography variant="h5" sx={{my: 2}}>
                  {/* Please enter one of the following{' '}
                  <strong>Email Address</strong> that is associated with your
                  account */}
                  <Trans i18nKey={'email_challenge_prompt'} />
                </Typography>
                <Typography variant="h5">
                  {/* Please enter one of the following{' '}
                  <strong>Email Address</strong> that is associated with your
                  account */}
                  <Trans i18nKey={'email_challenge_prompt_pt2'} />
                </Typography>
                <Box sx={{mt: 2}}>
                  {currentDiscordUserInfo.emails.map((email) => (
                    <Typography
                      key={email}
                      variant="h6"
                      sx={{fontWeight: 'bold'}}
                    >
                      {email}
                    </Typography>
                  ))}
                </Box>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={lodash.startCase(t('email'))}
                  autoFocus
                  onChange={(e) => {
                    setEmailInput(e.target.value);
                  }}
                  sx={{my: 2, maxWidth: '360px'}}
                />
                <Button
                  variant="contained"
                  size="large"
                  sx={{mt: 1}}
                  onClick={() => {
                    validateDiscordUserIdentity(
                      currentDiscordUserInfo.UserId,
                      emailInput,
                    )
                      .then((res) => {
                        if (res?.data?.code === 0) {
                          setActiveStep(2);
                        } else {
                          toast.warning(
                            'The email you entered does not match with our record',
                            {toastId: 'EMAIL_NOT_MATCH'},
                          );
                        }
                      })
                      .catch((err) =>
                        toast.error(
                          'Something went wrong, please try again later',
                          {toastId: 'EMAIL_VERIFY_API_FAILURE'},
                        ),
                      );
                  }}
                >
                  <Typography variant="h6">{t('submit')}</Typography>
                </Button>
              </>
            )}
            {activeStep === 2 && (
              <React.Fragment>
                <Typography sx={{mt: 2, mb: 1}}>
                  {/* We just sent you a password reset email to:{' '} */}
                  {t('email_sent_promt') + ': '}
                  <strong>{`${emailInput}`}</strong>
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                  <Box sx={{flex: '1 1 auto'}} />
                  {/* <Button onClick={handleReset}>Reset</Button> */}
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Box>

        <Button
          // variant="contained"
          size="large"
          sx={{
            textTransform: 'none',
            mt: '40px',
          }}
          onClick={() => navigate('/')}
        >
          <Typography variant="h6">{t('back_to_signin')}</Typography>
        </Button>
        <Button
          onClick={() => {
            i18n.changeLanguage(i18n.language === 'en_us' ? 'zh_cn' : 'en_us');
          }}
        >
          {t('LANGUAGE_SWITCH')}
        </Button>
        {/* <Typography component="h1" variant="h5">
          {t('Sign In')}
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{mt: 1}}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('Username')}
            autoFocus
          />
        </Box> */}
        <ZendeskComp />
      </Paper>
    </Grid>
  );
};

// const Copyright: React.FC<TypographyProps> = (props) => {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// };
